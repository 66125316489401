import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { HamburgetMenuClose, HamburgetMenuOpen } from '../Icons';
import RegisterModal from '../RegisterModal/RegisterModal';
import logoWithText from '../../assets/sitaram-bank-logo.png';

function Navbar({ getUserData, userData, setUserData }) {
  // const [userData, setUserData] = useState(null);
  const [isModalOpenRegister, setIsModalOpenRegister] = useState(false);
  const [click, setClick] = useState(false);

  const openModalRegister = () => {
    setIsModalOpenRegister(true);
  };

  const closeModalRegister = () => {
    setIsModalOpenRegister(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const logoutApp = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/user/logout`,
        {
          withCredentials: true,
        }
      );
      // localStorage.clear();
      localStorage.removeItem('userDetails');
      localStorage.removeItem('userId');
      setUserData(null);

      window.location.href = '/';
    } catch (error) {
      console.error('Error Logging Out : ', error);
    }
  };

  const handleClick = () => setClick(!click);

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink to="/" className="nav-logo" exact="true">
            <img src={logoWithText} className="logo-image" />
            <span className=" ml-2 font-bold whitespace-nowrap text-base md:text-lg lg:text-2xl uppercase">
              Sitaram Bank
            </span>
          </NavLink>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            {userData ? (
              <>
                <li
                  exact="true"
                  className="text-white xl:border-r xl:pr-5 xl:mr-1"
                >
                  {userData?.fullName && `Welcome, ${userData?.fullName}`}
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/topusers"
                    activeclassname="active"
                    className="nav-links"
                    onClick={handleClick}
                    exact="true"
                  >
                    Top Users
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/mantrapage"
                    activeclassname="active"
                    className="nav-links"
                    onClick={handleClick}
                    exact="true"
                  >
                    Mantralekhan
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/myperformance"
                    activeclassname="active"
                    className="nav-links"
                    onClick={handleClick}
                    exact="true"
                  >
                    My Performance
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/editprofile"
                    activeclassname="active"
                    className="nav-links"
                    onClick={handleClick}
                    exact="true"
                  >
                    Edit Profile
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/logout"
                    activeclassname="active"
                    className="nav-links"
                    onClick={logoutApp}
                    exact="true"
                  >
                    Log Out
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li exact="true" className="nav-links">
                  <NavLink
                    to="/"
                    activeclassname="active"
                    className="nav-links"
                    onClick={handleClick}
                    exact="true"
                  >
                    Welcome Guest
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/topusers"
                    activeclassname="active"
                    className="nav-links"
                    onClick={handleClick}
                    exact="true"
                  >
                    Top Users
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/register"
                    activeclassname="active"
                    className="nav-links"
                    onClick={handleClick}
                    exact="true"
                  >
                    Register
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/login"
                    activeclassname="active"
                    className="nav-links"
                    onClick={handleClick}
                    exact="true"
                  >
                    Log In
                  </NavLink>
                </li>
              </>
            )}
          </ul>
          {/* 
          {isModalOpenRegister ? (
            <RegisterModal
              isModalOpenRegister={openModalRegister}
              closeModalRegister={closeModalRegister}
            />
          ) : null} */}

          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuOpen />{' '}
              </span>
            )}
          </div>
        </div>
      </nav>
      <marquee className="sinduri">
              <strong>
              माँ सावित्री ज्योतिष अनुसंधान केंद्र द्वारा संचालित सीताराम बैंक में
          अपना खाता खोल करके पुण्य का संचय कर सभी दु:खों का निदान प्राप्त
          करें......
        </strong>
      </marquee>
    </>
  );
}

export default Navbar;
