import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage/Homepage';
import Mantrapage from './pages/Mantrapage/Mantrapage';
import TopUsersPage from './pages/TopUsers/TopUsers';
import MyPerformance from './pages/MyPerformance/MyPerformance';
import Navbar from './components/Navbar/Navbar';
import FAQ from './pages/FAQ/FAQ';
import Login from './components/Login/Login';
import Testimonials from './pages/Testimonials/Testimonials';
import EditProfile from './pages/EditProfile/EditProfile';
import { useState } from 'react';
import axios from 'axios';
import RegisterModal from './components/RegisterModal/RegisterModal';
import { AuthProvider } from './utility/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const [userData, setUserData] = useState(null);

  const getUserData = async () => {
    console.log('Getting User Data');
    try {
      axios.defaults.withCredentials = true;
      let response = await axios(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/login/success`,
        {
          method: 'GET',
          withCredentials: true,
        }
      );

      setUserData(response.data.data);
      localStorage.setItem('userId', response.data.data.id);
      localStorage.setItem('userDetails', JSON.stringify(response.data.data));
    } catch (error) {
      console.log('Error Fetching User Data:', error);
    }
  };

  return (
    <>
      <Router>
        <AuthProvider>
          <Navbar
            getUserData={getUserData}
            userData={userData}
            setUserData={setUserData}
          />
          <Routes>
            <Route path="/" element={<Homepage />}></Route>
            <Route path="/topusers" element={<TopUsersPage />}></Route>
            {/* required protection */}
            <Route
              path="/mantrapage"
              element={
                <ProtectedRoute authenticationRequired={true}>
                  <Mantrapage />
                </ProtectedRoute>
              }
            ></Route>

            {/* required protection */}
            <Route
              path="/myperformance"
              element={
                <ProtectedRoute authenticationRequired={true}>
                  <MyPerformance />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="/faq" element={<FAQ />}></Route>
            {/* <Route
            path="/BhagwanSwaminarayan"
            element={<BhagwanSwaminarayan />}
          ></Route> */}
            <Route
              path="/login"
              element={<Login getUserData={getUserData} />}
            ></Route>
            <Route path="/register" element={<RegisterModal />}></Route>
            <Route path="/testimonial" element={<Testimonials />}></Route>
            {/* required protection */}
            <Route
              path="/editprofile"
              element={
                <ProtectedRoute authenticationRequired={true}>
                  <EditProfile />
                </ProtectedRoute>
              }
            ></Route>
          </Routes>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
