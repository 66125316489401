import React from 'react';
import SitaramImage from '../assets/sitaram.jpg';
import { Link } from 'react-router-dom';

const Hero = ({ usercountdata, mantracountdata }) => {
  return (
    <>
      <div className="flex flex-wrap items-center justify-center px-6 pt-12 pb-4 bg-gradient-to-b from-white to-[#FFF7D4] w-full overflow-auto ">
        {/* content  */}
        <div className="w-full text-center  md:w-[65%]  md:text-left mb-0 ">
          <h1 className="text-[#800000] text-5xl lg:text-6xl xl:text-7xl mb-4">
            Discover the Power of the <span className='text-[#de4620]'>SITARAM </span> Mantra
          </h1>
          <p className="text-lg lg:text-2xl text-[#800000] mb-6">
            Embark on a spiritual journey of transformation with the divine
            mantra.
          </p>
          <Link to={'/mantrapage'}>
            <button className="bg-[#de4620] text-white py-3 px-6  rounded font-bold text-base uppercase cursor-pointer w-60">
              Start Mantralekhan
            </button>
          </Link>

          <p className="text-lg lg:text-2xl text-[#800000] mt-4">
            Total <span className='text-[#de4620]'> {mantracountdata}</span>  Mantralekhan by <span className='text-[#de4620]'>{usercountdata}</span>  Registered
            Users
          </p>
        </div>
        {/* image  */}

        <div className="w-full md:w-[35%] h-auto flex items-center justify-center ">
          <img src={SitaramImage} alt="" className="rounded-lg shadow-lg" />
        </div>
      </div>
    </>
  );
};

export default Hero;
