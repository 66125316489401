import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./RegisterModal.css";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RegisterModal.css';
import FooterWithLinks from "../FooterWithLinks/FooterWithLinks";

const RegisterModal = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const LoginWithGoogle = () => {
    window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, '_self');
  };

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setSelectedImage(imageFile);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      fullName,
      email,
      password,
      address,
      phone,
    };
    console.log('Form Data:', formData);
    localStorage.clear();
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/register`, formData, {
      headers: {
        'Content-Type': 'application/json',
        // Add other headers if required
      }
    })
      .then(response => {
        // Handle the response
        if(response) {
          console.log(response)
          toast.success(response.data.message, {
          position: "top-center", 
          autoClose: 2500
          });
          setFullName("");
          setEmail("");
          setPassword("");
          setAddress("");
          setPhone("");
          setSelectedImage(null);
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        }
      })
      .catch(error => {
          // Handle the error
          toast.error(error.response.data.data, {
          position: "top-center",
          autoClose: 2500
          });
        });
  };

  return (
    <>
    <ToastContainer />
      <div className="register-modal signup-page">
        <div className="register-box">
          <div className="tabs">
          <button
          className="login-google-btn mt-4"
          onClick={LoginWithGoogle}>
          Sign up With Google
        </button>
          </div>
          <div class="line-container">
  <span class="line-text">or</span>
</div>
          <div className="sigup-heading">
          <h2 className="title">Sign up</h2>
          <p>Create your account to get started.</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Full Name</label>
              <div className="control">
                <input
                  className="input required"
                  type="text"
                  placeholder="Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  className="input required"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input
                  type="password"
                  className="textarea required"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                ></input>
              </div>
            </div>
            <div className="field">
              <label className="label">Mobile Number</label>
              <div className="control">
                <input
                  type="tel"
                  className="textarea"
                  placeholder="Mobile number"
                  value={phone}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || (regex.test(e.target.value) && e.target.value.length <= 10)) {
                      setPhone(e.target.value);
                    }
                  }}
                  required
                ></input>
              </div>
            </div>
            {/* <div className="field">
              <input type="file" onChange={handleImageChange} />
              {selectedImage && (
                <div>
                  <p>Selected Image:</p>
                  <img src={URL.createObjectURL(selectedImage)} alt="Selected" />
                </div>
              )}
            </div> */}
            {/* <div className="button"> */}
              <div className="button-control">
                <button type="submit" className="button is-primary">
                  Submit
                </button>
              {/* </div> */}
            </div>
          </form>
          </div>
          </div>
          <FooterWithLinks />
          
    </>
  );
};

export default RegisterModal;
