import React from 'react';

import styles from './FooterWithLinks.module.css';

import maSavitriLogo from '../../assets/mashavitri logo.png';
import googlePlayBadge from '../../assets/google-play-badge.png'; // Add the image for the "Get it on Google Play" button here
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'; // Latest Twitter (X) icon

const FooterWithLinks = () => {
  const shareMessage = encodeURIComponent(
    'सीताराम मंत्र लेखन से मुझे शांति और सकारात्मक ऊर्जा मिली है। मैं आपको भी इस अद्भुत अनुभव का हिस्सा बनने के लिए आमंत्रित करता हूं। आज ही जुड़ें: https://mantralekhan.masavitrijyotish.com/ और अपनी आध्यात्मिक यात्रा की शुरुआत करें।'
   );

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=https://masavitrijyotish.com`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareMessage}`;
  const whatsappShareUrl = `https://wa.me/?text=${shareMessage}`;
  const playStoreUrl = `https://play.google.com/store/apps/details?id=com.masavitrijyotish.mantralekhan`; // Replace with your actual Play Store app link

  return (
    <footer className={`bg-sinduri ${styles.mantralekhanFooter} overflow-hidden`}>
      <div className="container-fluid p-2">
        <div className="row">
          <div className="col-md-5 d-flex align-items-center m-3">
            <img
              src={maSavitriLogo}
              style={{ maxWidth: '100px', height: 'auto' }}
              alt="Maa Savitri Logo"
            />
            <h5>Maa Savitri Jyotish Anusandhan Kendra</h5>
          </div>
          <div className="col-md-3 m-3">
            <h5 className="fw-bold">Useful Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/faq">Frequently Asked Questions</Link>
              </li>
              <li>
                <a href="https://masavitrijyotish.com/about-us" target="_blank" rel="noopener noreferrer">
                  About Us
                </a>
              </li>
              <li>
                <a href="https://masavitrijyotish.com/contact-us" target="_blank" rel="noopener noreferrer">
                  Contact Us
                </a>
              </li>
            </ul>
            <a
              href={playStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={`d-block ${styles.playStoreLink}`}
            >
              <img
                src={googlePlayBadge}
                alt="Get it on Google Play"
                className="mt-3"
                style={{ width: '150px', height: 'auto' }}
              />
            </a>
          </div>
          <div className="col-md-3 m-3">
            <h5 className="fw-bold">Support Us</h5>
            <p className="m-0">
              To support us, you can make donation via UPI to{' '}
              <a href="upi://pay?pa=m.s.j.a.mum@okhdfcbank&cu=INR">
                m.s.j.a.mum@okhdfcbank
              </a>
            </p>
            <h5 className="fw-bold mt-3">Invite a friend</h5>
            <div className="d-flex">
              <a
                href={facebookShareUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={`me-3 ${styles.socialIcon}`}
                style={{ backgroundColor: '#4267B2' }} // Facebook color
              >
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
              <a
                href={twitterShareUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={`me-3 ${styles.socialIcon}`}
                style={{ backgroundColor: '#000000' }} // Black for X (Twitter)
              >
                <FontAwesomeIcon icon={faXTwitter} size="lg" />
              </a>
              <a
                href={whatsappShareUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.socialIcon}`}
                style={{ backgroundColor: '#25D366' }} // WhatsApp green
              >
                <FontAwesomeIcon icon={faWhatsapp} size="lg" />
              </a>
            </div>
          </div>
        </div>
        <hr className="m-0" />
        <div className="row">
          <p className="text-center m-2">
            Developed & Maintained by{' '}
            <a
              className="fw-bold"
              href="https://www.flutebyte.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Flutebyte Technologies
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterWithLinks;
