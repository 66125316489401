import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import SitaramImage from '../../assets/sitaram.png';
import sitaramSound from '../../assets/sitaram_sound.mp3';
import './Mantrapage.css';
import FooterWithLinks from '../../components/FooterWithLinks/FooterWithLinks';
import ProgressBar from '@ramonak/react-progress-bar';

function Mantrapage() {
  const [word, setWord] = useState('');
  const [lines, setLines] = useState(Array(55).fill(''));
  const [wordCount, setWordCount] = useState(0);
  const [autoEnter, setAutoEnter] = useState(false);
  const [userData, setUserData] = useState(null);
  const [typingCount, setTypingCount] = useState(0);
  const [isTypingDisabled, setIsTypingDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const apiUrl = process.env.REACT_APP_SERVER_URL;
  const wordPattern = 'sitaram';
  const audioRef = useRef(null);

  useEffect(() => {
    const getUserData = async () => {
      try {
        axios.defaults.withCredentials = true;
        const response = await axios(`${apiUrl}/api/v1/login/success`, {
          method: 'GET',
          withCredentials: true,
        });

        setUserData(response.data.data);
        localStorage.setItem('userId', response.data.data.id);
        localStorage.setItem('userDetails', JSON.stringify(response.data.data));
      } catch (error) {
        console.log('Error Fetching User Data.', error);
        window.location.href = '/login';
      }
    };
    getUserData();
  }, [apiUrl]);

  useEffect(() => {
    let timer;
    if (isTimerRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsTypingDisabled(false);
      setShowPopup(false);
      setTypingCount(0);
      setIsTimerRunning(false);
      setTimeLeft(60);
    }
    return () => clearInterval(timer);
  }, [isTimerRunning, timeLeft]);

  const addCount = async () => {
    // const accessToken = Cookies.get('accessToken');
    // console.log(accessToken);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/mantralekhan`,
        { id: localStorage.getItem('userId') },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // console.log(response.data);
    } catch (error) {
      console.error('There was a problem with your Axios request:', error);
    }
  };

  const handleMantraInputSuccess = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
    addCount();
    addWordToLines();
    setTypingCount((prevCount) => prevCount + 1);

    if (typingCount + 1 >= 151) {
      setIsTypingDisabled(true);
      setShowPopup(true);
    }
  };

  const addWordToLines = () => {
    const newLines = [...lines];
    const lineIndex = newLines.findIndex((line) => line === '');
    if (lineIndex !== -1) {
      newLines[lineIndex] = wordPattern;
      setLines(newLines);
      setWord('');
      setWordCount(wordCount + 1);

      if (lineIndex === 54) {
        setLines(Array(55).fill(''));
      }
    } else {
      setWord('');
    }
  };

  const handleInputChange = (e) => {
    if (isTypingDisabled) return;

    const input = e.target.value.slice(0, 12);
    const newWord = input
      .toLowerCase()
      .split('')
      .filter((char, index) => index < 12 && char === wordPattern[index])
      .join('');
    setWord(newWord);

    if (newWord === wordPattern && autoEnter) {
      handleMantraInputSuccess();
      if (!isTimerRunning) {
        setIsTimerRunning(true);
      }
    }
  };

  const handleEnterPress = (e) => {
    if (
      !autoEnter &&
      e.key === 'Enter' &&
      word === wordPattern &&
      !isTypingDisabled
    ) {
      handleMantraInputSuccess();
      if (!isTimerRunning) {
        setIsTimerRunning(true);
      }
      e.preventDefault();
    }
  };
  const progressPercentage = (timeLeft / 60) * 100;
  return (
    <div className="mantra-main">
      <div>
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
            {/* popup */}
            <div className="w-full max-w-lg text-center  ">
              {/* header */}
              <div className="w-full bg-[#de4620] p-4 text-white font-black rounded-t-2xl relative">
                <h2>ATTENTION !</h2>
              </div>
              {/* content */}
              <div className="bg-white w-full p-3 rounded-b-2xl">
                {/* message */}
                <div className="text-lg md:text-2xl font-semibold">
                  <p className="text-[#de4620]">
                    To maintain optimal performance, please limit your "Sitaram"
                    mantra entries to <span className="text-blue-700">151</span>{' '}
                    per minute.
                  </p>
                  <ProgressBar
                    completed={progressPercentage}
                    bgColor="#4CAF50"
                    height="10px"
                    isLabelVisible={false}
                  />
                  <p className="mt-2 text-[#de4620]">
                    You can continue after{' '}
                    <span className="text-blue-700">{timeLeft} </span> seconds
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center">
        <audio ref={audioRef} src={sitaramSound} />
        <img alt="Sitaram" src={SitaramImage} style={{ height: 300 }} />
        <div className="mt-3">
          <input
            type="text"
            value={word}
            onChange={handleInputChange}
            onPaste={(e) => e.preventDefault()}
            onKeyDown={handleEnterPress}
            placeholder="sitaram"
            className="mantrainput"
          />
          <p className="text-center">Total {wordCount} and counting...</p>
        </div>
        <label>
          <input
            type="checkbox"
            checked={autoEnter}
            onChange={() => setAutoEnter(!autoEnter)}
            className="enterCheckBox"
          />
          &nbsp; Skip Enter Key
        </label>
      </div>
      <div className="lines">
        {lines.map((line, index) => (
          <div key={index} className="line">
            {line}
          </div>
        ))}
      </div>

      <FooterWithLinks />
    </div>
  );
}

export default Mantrapage;
