import React, { useEffect, useState } from 'react';
import FooterWithLinks from '../../components/FooterWithLinks/FooterWithLinks';
import mashavitrilogo from '../../assets/mashavitri logo.png';
import Hero from '../../components/Hero';
import Popup from '../../components/Popup';

const Homepage = () => {
  const [counts, setCounts] = useState({ mantraCount: 0, userCount: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/mantralekhan/count`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setCounts({
          mantraCount: data.data.mantraCount,
          userCount: data.data.userCount,
        });
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="w-full h-max bg-gradient-to-b from-white to-[#FFF7D4] overflow-hidden ">
        <Hero
          usercountdata={counts.userCount}
          mantracountdata={counts.mantraCount}
        />
        <Popup />
        {/* about  */}
        <div className="w-full flex flex-col items-center justify-center py-3 text-[#800000]">
          <h3 className="text-center my-5 font-bold">
            About the SITARAM Mantra
          </h3>
          <div className="w-[90%]  flex  flex-col md:flex-row items-center justify-center ">
            <div className="w-full md:w-[60%]  ">
              <p className="text-md ">
                The <span style={{ color: '#de4620' }}>SITARAM</span> Mantra
                transcends the limits of human understanding, embodying profound
                spiritual truths. <span style={{ color: '#de4620' }}>Ram</span>{' '}
                represents the universal consciousness—both within and around
                us—symbolizing masculine energy and truth. In contrast,
                <span style={{ color: '#de4620' }}> Sita </span>embodies the
                Shakti of the universe, the divine feminine energy and power.
              </p>
              <p className="text-md ">
                The union of these two energies ignites the enlightenment of
                <span style={{ color: '#de4620' }}> Kundalini Shakti</span>.
                Ram, known as{' '}
                <span style={{ color: '#de4620' }}>Maryada Purushottam</span>,
                epitomizes the ideal man and serves as the
                <span style={{ color: '#de4620' }}></span> Beeja Mantra for the
                <span style={{ color: '#de4620' }}> Manipur Chakra </span>(Solar
                Plexus).<span style={{ color: '#de4620' }}>Sita</span>, often
                referred to as the daughter of the Earth, is intimately
                connected with the
                <span style={{ color: '#de4620' }}> Muladhara Chakra </span>
                (Root Chakra).
              </p>
              <p className="text-md ">
                By chanting and writing{' '}
                <span style={{ color: '#de4620' }}>"SITARAM," </span>you can
                ignite your inner fire, cleansing physical, mental, and karmic
                impurities. This practice enhances your willpower, clarifies
                your goals, and cultivates the wisdom needed to navigate life’s
                journey. It fosters health, wealth, and abundance—blessings of
                the <span style={{ color: '#de4620' }}>Muladhara Chakra.</span>
              </p>
              <p className="text-md ">
                Writing <span style={{ color: '#de4620' }}>"SITARAM" </span>
                resonates through all the{' '}
                <span style={{ color: '#de4620' }}>Nadis </span>and{' '}
                <span style={{ color: '#de4620' }}>Chakras </span>in your body,
                releasing toxins and restoring hormonal balance. As you chant,
                you enter a profound state of peace and relaxation, dissolving
                habitual stress and tension. Negative karma dissipates, allowing
                your inner bliss to flourish.
              </p>
              <p className="text-md ">
                Consistent and disciplined chanting of{' '}
                <span style={{ color: '#de4620' }}>"SITARAM"</span>revitalizes
                your spirit, diminishing the ego and enveloping you in
                protective vibrations. This mantra shields you from negativity,
                psychic attacks, and potential harm. The transformative power of
                the <span style={{ color: '#de4620' }}>SITARAM </span>Mantra can
                profoundly alter your being, radiating peace and inner bliss
                that manifests as{' '}
                <span style={{ color: '#de4620' }}>Tejas </span>or spiritual
                glow.
              </p>
              <p className="text-md ">
                Experience improved physical and mental health, harmonious
                relationships, and a deeper connection with your inner self and
                the divine. Embrace the fulfillment that the{' '}
                <span style={{ color: '#de4620' }}>SITARAM </span>Mantra brings
                to your life.
              </p>
            </div>
            <div className="w-full max-w-sm md:w-[30%] m-5 ">
              <img src={mashavitrilogo} alt="" />
              <h3 className="mt-1 text-center">
                Maa Savitri Jyotish Anusandhan Kendra
              </h3>
            </div>
          </div>
        </div>

        {/* milestones  */}
        <div className="w-full pb-5 text-[#800000]">
          <div className="py-3 mt-2">
            <h3 className="text-center mb-2 font-bold">
              Achieve Spiritual Milestones
            </h3>
            <p className="text-center ">
              It has been decided to encourage the writers through the institute
              for writing Sitaram Mantra, which is as follows :
            </p>
          </div>

          {/* cards  */}
          <div className="w-full  px-5 flex flex-wrap gap-5 mt-3 justify-center ">
            <div className="bg-[#f9f6e9] rounded-3xl shadow-2xl w-full lg:w-[28%] text-center px-3 py-4 flex flex-col justify-center items-center ">
              <h4 className=" font-bold">1,01,000 Mantras</h4>
              <h5 className=" font-normal">Free Jyotish Consultation</h5>
              <p>
                The person who writes Sitaram Mantra
                <span className="sinduri"> 1,01,000</span> times within a month
                from the date of registration will get a{' '}
                <span className="sinduri">free Jyotish consultation.</span>
              </p>
            </div>
            <div className="bg-[#f9f6e9] rounded-3xl shadow-2xl w-full lg:w-[28%] text-center px-3 py-4 flex flex-col justify-center items-center ">
              <h4 className=" font-bold">1,61,000 Mantras</h4>
              <h5 className=" font-normal">Sampurna Jeevan Darpan</h5>
              <p>
                The person who writes Sitaram Mantra
                <span className="sinduri"> 1,61,000</span> times within a month
                or before from the date of registration will get his{' '}
                <span className="sinduri">
                  <a
                    className="sinduri hover:no-underline"
                    target="_blank"
                    href="https://masavitrijyotish.com/jeewan-darpan-gold/"
                  >
                    Sampurna Jeevan Darpan
                  </a>
                </span>{' '}
                for free
              </p>
            </div>{' '}
            <div className="bg-[#f9f6e9] rounded-3xl shadow-2xl w-full lg:w-[28%] text-center px-3 py-4 flex flex-col justify-center items-center ">
              <h4 className=" font-bold">11,50,000 Mantras</h4>
              <h5 className=" font-normal">Become a Yajman for Free</h5>
              <p>
                The person who writes Sitaram Mantra{' '}
                <span className="sinduri"> 11,50,000</span> times within one
                year or before from the date of registration will get the
                opportunity to become Yajman absolutely free for{' '}
                <span>
                  <a
                    className="sinduri hover:no-underline"
                    target="_blank"
                    href="https://masavitrijyotish.com/kaal-sarp-dosh/"
                  >
                    Kalsarp Yog
                  </a>
                </span>{' '}
                and <span className="sinduri">Grah Shanti Yagya</span> organized
                annually by the trust on the occasion of Nagpanchami.
              </p>
            </div>
          </div>
        </div>

        <FooterWithLinks />
      </div>
    </>
  );
};

export default Homepage;
